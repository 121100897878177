import { render, staticRenderFns } from "./SchoolReport.vue?vue&type=template&id=ccac29e0&scoped=true"
import script from "./SchoolReport.vue?vue&type=script&lang=js"
export * from "./SchoolReport.vue?vue&type=script&lang=js"
import style0 from "./SchoolReport.vue?vue&type=style&index=0&id=ccac29e0&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "ccac29e0",
  null
  
)

export default component.exports