<template>
  <div class="col report_page_wrapper">
    <card>
      <div class="some_details">
        <div class="every_part shadow-sm">
          <span class="d-block w-100 title">
            {{ $t("admin_dashboard_type.school_seats_numbers") }}
          </span>
          <span class="d-block w-100">43543654 / 89709</span>
        </div>
        <div class="every_part shadow-sm">
          <span class="d-block w-100 title">
            {{ $t("admin_dashboard_type.school_teachers_numbers") }}
          </span>
          <span class="d-block w-100">5667</span>
        </div>
        <div class="every_part shadow-sm">
          <span class="d-block w-100 title">
            {{ $t("admin_dashboard_type.school_students_numbers") }}
          </span>
          <span class="d-block w-100">5667</span>
        </div>
        <div class="every_part shadow-sm">
          <span class="d-block w-100 title">
            {{ $t("admin_dashboard_type.school_active_classes_numbers") }}
          </span>
          <span class="d-block w-100">5667</span>
        </div>
      </div>
    </card>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
.report_page_wrapper {
  .some_details {
    display: flex;
    gap: 1%;
    flex-wrap: wrap;
    padding: 15px;
    .every_part {
      width: calc(96% / 4);
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      align-items: center;
      text-align: center;
      padding: 10px;
      min-height: 150px;
      transition: 0.5s;
      border-radius: 8px;
      &:hover {
        background-color: #eee;
      }
      .title {
        font-weight: bold;
        color: #555;
        font-size: 17px;
      }
    }
  }
}
</style>
